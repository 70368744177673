/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreateSafetyLocal,
  IDetailSafetyLocal,
  ISafetyLocal,
} from '../../libs/apis/safety/types';
import { ICreateSafetyState, IDetailSafetyState, ISafetyState } from './types';
import { apiSdk } from '../../libs/apis';

export const getAllSafety = createAsyncThunk(
  '/admin/safety-notifications',
  async (safety: ISafetyLocal, { rejectWithValue }) => {
    try {
      const data: ISafetyState = await apiSdk.safetyApis.getAllSafety(safety);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getDetailSafety = createAsyncThunk(
  '/admin/safety-notifications/detail',
  async (safety: IDetailSafetyLocal, { rejectWithValue }) => {
    try {
      const data: IDetailSafetyState =
        await apiSdk.safetyApis.getDetailSafety(safety);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createSafety = createAsyncThunk(
  '/admin/safety-notifications/create',
  async (safety: ICreateSafetyLocal, { rejectWithValue }) => {
    try {
      const data: ICreateSafetyState =
        await apiSdk.safetyApis.createSafety(safety);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
