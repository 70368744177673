/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { createSafety, getAllSafety, getDetailSafety } from './action';
import {
  ICreateSafetyError,
  ICreateSafetyState,
  IDetailSafetyState,
  ISafetyState,
} from './types';

interface IState {
  list: ISafetyState;
  detail: IDetailSafetyState;
  create: ICreateSafetyState;
  errors?: ICreateSafetyError;
}

const initValue = {
  safety_notifications_id: 0,
  title: '',
  detail: '',
  created_by: 0,
  created_at: '',
  target_area: [],
  user_type: 0,
};

const defaultState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: initValue,
};

export const initialState: IState = {
  list: {
    message: '',
    success: false,
    status: HTTP_STATUS.UNAUTHORIZED,
    data: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0,
      items: [],
    },
  },
  detail: defaultState,
  create: defaultState,
};

const safetySlice = createGenericSlice({
  name: 'safety',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = undefined;
    },

    clearCreateState: (state) => {
      state.create.message = '';
      state.create.success = false;
      state.create.data = initValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSafety.rejected, (state, action: any) => {
      state.list.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.list.message = action.payload.message;
        state.list.success = action.payload.success;
      }
    });

    builder.addCase(
      getAllSafety.fulfilled,
      (state, action: PayloadAction<ISafetyState>) => {
        state.list.success = action.payload.success;
        state.list.data = action.payload.data;
        state.list.status = action.payload.status;
      },
    );

    builder.addCase(getDetailSafety.pending, (state, _) => {
      state.detail = initialState.detail;
    });

    builder.addCase(getDetailSafety.rejected, (state, action: any) => {
      state.detail.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.detail.message = action.payload.message;
        state.detail.success = action.payload.success;
      }
    });

    builder.addCase(
      getDetailSafety.fulfilled,
      (state, action: PayloadAction<IDetailSafetyState>) => {
        state.detail.success = action.payload.success;
        state.detail.data = action.payload.data;
        state.detail.status = action.payload.status;
      },
    );

    builder.addCase(createSafety.rejected, (state, action: any) => {
      state.create.status = action.payload.status;
      state.create.success = action.payload.success;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(
      createSafety.fulfilled,
      (state, action: PayloadAction<ICreateSafetyState>) => {
        state.create.success = action.payload.success;
        state.create.data = action.payload.data;
        state.create.status = action.payload.status;
      },
    );
  },
});

export const { clearErrors, clearCreateState } = safetySlice.actions;

export default safetySlice.reducer;
