import { AxiosInstance } from 'axios';
import { ICreateNotificationLocal } from './types';
import { ICreateNotificationsState } from '../../../redux/notifications/types';

export class NotificationApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async createNotification(
    notification: ICreateNotificationLocal,
  ): Promise<ICreateNotificationsState> {
    const { data } = await this.axiosInstance.post('/api/admin/notifications', {
      title: notification.title,
      detail: notification.detail,
      target_area: notification.target_area,
    });
    return data;
  }
}
