import { AxiosInstance } from 'axios';
import { ICreateSafetyLocal, IDetailSafetyLocal, ISafetyLocal } from './types';
import {
  ICreateSafetyState,
  IDetailSafetyState,
  ISafetyState,
} from '../../../redux/safety/types';

export class SafetyApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getAllSafety(safety: ISafetyLocal): Promise<ISafetyState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/safety-notifications?page=${safety.page}`,
    );
    return data;
  }

  async getDetailSafety(
    safety: IDetailSafetyLocal,
  ): Promise<IDetailSafetyState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/safety-notifications/${safety.id}`,
    );
    return data;
  }

  async createSafety(safety: ICreateSafetyLocal): Promise<ICreateSafetyState> {
    const { data } = await this.axiosInstance.post(
      '/api/admin/safety-notifications',
      {
        title: safety.title,
        detail: safety.detail,
        user_type: safety.user_type,
        target_area: safety.target_area,
        all_areas: safety.all_areas,
      },
    );
    return data;
  }
}
