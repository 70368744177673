/* eslint-disable no-undef */
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { translate } from '../../libs/utils';
import Checkbox from '../Checkbox';

enum SeminarType {
  LOCAL = 0,
  ONLINE = 1,
  ALL = 2,
}
interface Props {
  seminarType: number;
  setSeminarType: (type: number) => void;
  webURL: string;
  setWebURL: (url: string) => void;
}

const EventTypeField: FC<Props> = ({
  seminarType,
  setSeminarType,
  webURL,
  setWebURL,
}) => {
  const [isLocalEvent, setIsLocalEvent] = useState<boolean>(
    seminarType !== SeminarType.ONLINE,
  );
  const [isOnlineEvent, setIsOnlineEvent] = useState<boolean>(
    seminarType !== SeminarType.LOCAL,
  );

  const handleClickLocalEventOptions = () => {
    setIsLocalEvent(!isLocalEvent);
  };

  const handleClickOnlineEventOptions = () => {
    setIsOnlineEvent(!isOnlineEvent);
  };

  useEffect(() => {
    if (isLocalEvent && isOnlineEvent) setSeminarType(SeminarType.ALL);
    else if (isLocalEvent && !isOnlineEvent) setSeminarType(SeminarType.LOCAL);
    else if (!isLocalEvent && isOnlineEvent) setSeminarType(SeminarType.ONLINE);
  }, [isLocalEvent, isOnlineEvent, setSeminarType]);

  return (
    <Stack flexDirection={'row'}>
      <Checkbox
        checked={isLocalEvent}
        label={translate('seminar.detail.item.venue_event')}
        onClick={handleClickLocalEventOptions}
      />
      <Checkbox
        checked={isOnlineEvent}
        label={translate('seminar.detail.item.web_event')}
        onClick={handleClickOnlineEventOptions}
      />
      <TextField
        label={translate('seminar.detail.item.web_event.input.label')}
        variant="outlined"
        value={webURL}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setWebURL(event.target.value);
        }}
        placeholder={translate(
          'seminar.detail.item.web_event.input.placeholder',
        )}
      />
    </Stack>
  );
};

export default EventTypeField;
