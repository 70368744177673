export const API_BASE_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:8000';

export enum HTTP_STATUS {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  UNPROCESSABLE_CONTENT = 422,
}

export enum TOKEN_KEYS {
  ACCESS_TOKEN = '_token',
  REFRESH_TOKEN = '_refresh_token',
  EXPIRES_IN = '_expires_in',
}

export enum UserType {
  REGULAR = 1,
  ASSOCIATE = 2,
  ADMIN_STAFF = 3,
  ALL_MEMBER = 4,
}

export const supportedLanguage = [
  { language: 'japan', code: 'JP' },
  { language: 'english', code: 'en' },
];

export enum SeminarType {
  LOCAL_EVENT = 0,
  ONLINE_EVENT = 1,
  ALL = 2,
}

export enum AdditionalDay {
  DATE = 'date',
  START_TIME = 'start_time',
  END_TIME = 'end_time',
}
