import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Layout from '../../components/Layout';
import Chip from '../../components/Chip';
import { useAppDispatch } from '../../redux';
import { seminarSelector } from '../../redux/seminars/selectors';
import { getSeminar } from '../../redux/seminars/action';
import { ISeminar } from '../../redux/seminars/types';
import { translate } from '../../libs/utils';
import { getDate, getTime } from '../../libs/utils/formatDate';
import {
  BLACK,
  LIGHT_PURPLE,
  MAIN_LIGHT_COLOR,
  TYPOGRAPHY,
  WHITE,
} from '../../themes/colors';
import { TITLE, TITLE_LINE_HEIGHT } from '../../themes/fonts';
import TableButton from '../../components/table/TableButton';
import TargetMemberTypeField from '../../components/seminars/TargetMemberTypeField';

enum SeminarType {
  VENUE_EVENT = 0,
  WEB_EVENT = 1,
}

const useStyles = makeStyles({
  container: {
    maxWidth: '1226',
    margin: '0 32px',
  },
  labelContainer: {
    minWidth: '240px',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  textLabel: {
    color: WHITE,
    fontSize: '14px !important',
    fontWeight: 'bold !important',
  },
  lectureText: {
    '& .MuiInputBase-input': {
      fontSize: '15px',
    },
  },
  height70: { height: '70px' },
  height83: { height: '83px' },
  height140: { height: '140px' },
  height170: { height: '170px' },
  height190: { height: '190px' },
});

const DetailScreen: React.FC = () => {
  const styles = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const seminar: ISeminar = useSelector(seminarSelector);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) dispatch(getSeminar({ seminarId: parseInt(id) }));
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [dispatch, id]);

  const handleBackButton = () => navigate('/seminars');

  const renderLabelField = (
    label: string,
    content: React.ReactNode,
    height: string,
  ) => (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={3}
      className={`${styles.container} ${height}`}
    >
      <Box className={styles.labelContainer}>
        <Typography className={styles.textLabel}>{label}</Typography>
      </Box>
      {content}
    </Stack>
  );

  const renderTextField = (value: string, multiline = false, rows = 1) => (
    <TextField
      sx={{
        width: '100%',
        color: BLACK,
        fontSize: TITLE,
        lineHeight: TITLE_LINE_HEIGHT,
      }}
      variant={multiline ? 'outlined' : 'standard'}
      value={value}
      multiline={multiline}
      rows={rows}
    />
  );

  const renderDateTimeTextField = (
    value: string,
    label: string,
    width: string,
  ) => {
    return (
      <TextField
        label={label}
        variant="filled"
        value={value}
        sx={{ width: width }}
      />
    );
  };

  const renderDateTimeFields = (
    additionalDates: {
      date: string;
      start_time: string;
      end_time: string;
    }[] = [],
    startDate: string,
    startTime: string,
    endTime?: string,
    endDate?: string,
  ) => (
    <Stack
      marginTop={1}
      marginBottom={1}
      overflow={'auto'}
      height={additionalDates?.length >= 1 ? '190px' : undefined}
    >
      <Stack flexDirection="row" alignItems="left" gap={1}>
        {renderDateTimeTextField(
          startDate,
          translate('seminar.detail.item.start_date'),
          '135px',
        )}
        {renderDateTimeTextField(
          startTime,
          translate('seminar.detail.item.start_time'),
          '95px',
        )}
        {endDate && <Typography sx={{ margin: '0 40px' }}>to</Typography>}
        {endDate &&
          renderDateTimeTextField(
            endDate,
            translate('seminar.detail.item.end_date'),
            '135px',
          )}
        {endTime &&
          renderDateTimeTextField(
            endTime,
            translate('seminar.detail.item.end_time'),
            '95px',
          )}
      </Stack>
      <Stack>
        {additionalDates?.map((additionalDate, index) => (
          <Stack
            flexDirection="row"
            alignItems="left"
            gap={1}
            key={index}
            marginTop={1}
          >
            {renderDateTimeTextField(
              getDate(additionalDate.date),
              translate('seminar.detail.item.start_date'),
              '135px',
            )}
            {renderDateTimeTextField(
              getTime(`${additionalDate.date} ${additionalDate.start_time}`),
              translate('seminar.detail.item.start_time'),
              '95px',
            )}
            {renderDateTimeTextField(
              getTime(`${additionalDate.date} ${additionalDate.end_time}`),
              translate('seminar.detail.item.end_time'),
              '95px',
            )}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );

  const renderTargetAreasFields = () => {
    return (
      <Stack
        alignContent="flex-start"
        flexWrap="wrap"
        flexDirection="row"
        gap={1}
        sx={{
          flexGrow: 1,
          backgroundColor: '#F2F2F2',
          borderRadius: '5px',
          padding: '10px',
          height: '140px',
        }}
      >
        {seminar.target_area.map((area) => (
          <Chip
            key={area.area_id}
            label={area.area_name}
            sx={{
              backgroundColor: LIGHT_PURPLE,
              width: '80px',
              height: '22px',
            }}
            textSx={{
              color: TYPOGRAPHY,
              fontSize: '11px',
              fontWeight: '500',
            }}
          />
        ))}
      </Stack>
    );
  };

  const renderCheckbox = (checked: boolean, label: string) => {
    return (
      <FormControlLabel
        control={<Checkbox checked={checked} />}
        label={label}
      />
    );
  };

  const renderEventTypeField = () => {
    return (
      <>
        {renderCheckbox(
          seminar.seminar_type === SeminarType.VENUE_EVENT,
          translate('seminar.detail.item.venue_event'),
        )}
        {renderCheckbox(
          seminar.seminar_type === SeminarType.WEB_EVENT,
          translate('seminar.detail.item.web_event'),
        )}
        <TextField
          label={translate('seminar.detail.item.web_event.input.label')}
          variant="outlined"
          value={!seminar.web_url ? '' : seminar.web_url}
          placeholder={translate(
            'seminar.detail.item.web_event.input.placeholder',
          )}
        />
      </>
    );
  };

  return (
    <Layout title={translate('seminar.detail.screen.title')}>
      {isLoading && (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && (
        <>
          {renderLabelField(
            translate('seminar.detail.title'),
            renderTextField(seminar.title),
            styles.height83,
          )}
          {renderLabelField(
            translate('seminar.detail.organizer'),
            renderTextField(seminar.organizer_name),
            styles.height83,
          )}
          {renderLabelField(
            translate('seminar.detail.lecture_details'),
            renderTextField(seminar.detail, true, 4),
            styles.height140,
          )}
          {renderLabelField(
            translate('seminar.detail.item.target_member_type'),
            <TargetMemberTypeField
              targetMemberType={seminar.target_member_type}
            />,
            styles.height83,
          )}
          {renderLabelField(
            translate('seminar.detail.target_audience'),
            renderTargetAreasFields(),
            styles.height170,
          )}
          {renderLabelField(
            translate('seminar.detail.opening_day'),
            renderDateTimeFields(
              seminar.additional_dates,
              getDate(seminar.seminar_start_time.toString()),
              getTime(seminar.seminar_start_time.toString()),
              getTime(seminar.seminar_end_time.toString()),
            ),
            styles.height190,
          )}
          {renderLabelField(
            translate('seminar.detail.apply_time'),
            <Stack flexDirection="row" alignItems="center" gap={1}>
              {renderDateTimeFields(
                [],
                getDate(seminar.apply_start_at.toString()),
                getTime(seminar.apply_start_at.toString()),
                getTime(seminar.apply_end_at.toString()),
                getDate(seminar.apply_end_at.toString()),
              )}
            </Stack>,
            styles.height70,
          )}
          {renderLabelField(
            translate('seminar.detail.publicity'),
            <Box sx={{ flexGrow: 1 }}>
              <FormControlLabel
                control={<Switch checked={seminar.isOpen} />}
                label={translate('seminar.detail.item.public')}
              />
            </Box>,
            styles.height70,
          )}
          {renderLabelField(
            translate('seminar.detail.event_type'),
            renderEventTypeField(),
            styles.height70,
          )}
          <Stack
            flexDirection="row"
            justifyContent="center"
            gap={5}
            sx={{ marginY: '40px' }}
          >
            <TableButton
              label={translate('button.back')}
              onClick={handleBackButton}
            />
          </Stack>
        </>
      )}
    </Layout>
  );
};

export default DetailScreen;
