import { Checkbox, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { translate } from '../../libs/utils';
import { NOTICE } from '../../themes/colors';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

interface Props {
  acceptBuddy: number;
  setAcceptBuddy: (isAccept: number) => void;
}

const AcceptBuddyField: FC<Props> = ({ acceptBuddy, setAcceptBuddy }) => {
  return (
    <Stack flexDirection="row">
      <Checkbox
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={<RadioButtonCheckedIcon />}
        checked={acceptBuddy ? true : false}
        onClick={() => setAcceptBuddy(acceptBuddy === 1 ? 0 : 1)}
      />
      <Typography
        sx={{ fontSize: '12px', color: NOTICE, fontWeight: '600' }}
        alignContent={'center'}
      >
        {translate('seminar.crate.accept_buddy')}
      </Typography>
    </Stack>
  );
};

export default AcceptBuddyField;
