/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { translate } from '../../libs/utils';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  LIGHT_PURPLE,
  MAIN_LIGHT_COLOR,
  NOTICE,
  TYPOGRAPHY,
  WHITE,
} from '../../themes/colors';
import Button from '../../components/Button';
import { IArea } from '../../redux/areas/types';
import { useSelector } from 'react-redux';
import { areasSelector } from '../../redux/areas/selectors';
import { useAppDispatch } from '../../redux';
import { getAreas } from '../../redux/areas/action';
import Chip from '../../components/Chip';
import { useNavigate } from 'react-router-dom';
import { errorsSelector } from '../../redux/notifications/selectors';
import ErrorMessage from '../../components/ErrorMessage';
import { TOPIC_LABEL } from '../../themes/fonts';
import CreatePopup from '../../components/popup/CreatePopup';
import { ICreateNotificationError } from '../../redux/notifications/types';
import { ICreateNotificationLocal } from '../../libs/apis/notifications/types';
import { createNotification } from '../../redux/notifications/action';
import { clearCreateState, clearErrors } from '../../redux/notifications';
import FormProvider from '../../components/FormProvider';
import { useForm } from 'react-hook-form';
import TableButton from '../../components/table/TableButton';

const useStyles = makeStyles({
  labelContainer: {
    minWidth: 'calc(241 / 1218 * 100%) !important',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '18px',
    height: '100%',
  },

  textLabel: {
    color: WHITE,
    fontSize: `${TOPIC_LABEL} !important`,
    fontWeight: 'bold !important',
    textAlign: 'center',
  },

  inputField: {
    width: 'calc(718 / 959 * 100%)',
  },

  selectContainer: {
    width: '160px',
    marginLeft: '40px',
  },

  customSelectInput: {
    borderRadius: '12px !important',
    height: '50px !important',
  },
});

const defaultValue: ICreateNotificationLocal = {
  title: '',
  detail: '',
  target_area: [],
};

const CreateNotificationScreen: React.FC = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [areas, setAreas] = useState<IArea[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<IArea[]>([]);
  const [value, setValue] = useState<string>('');
  const [dataAdd, setDataAdd] =
    useState<ICreateNotificationLocal>(defaultValue);
  const [errorsMessage, setErrorsMessage] = useState<
    ICreateNotificationError | undefined
  >({});
  const dispatch = useAppDispatch();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const methods = useForm();
  const { handleSubmit } = methods;

  const errors: ICreateNotificationError | undefined =
    useSelector(errorsSelector);
  const data: IArea[] = useSelector(areasSelector);

  const onSubmit = handleSubmit(async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await dispatch(createNotification(dataAdd));
    if (response.payload.success == true) {
      setOpenCreateModal(true);
      dispatch(clearErrors());
      dispatch(clearCreateState());
    }
  });

  const handleBackButton = () => navigate('/seminars');

  useEffect(() => {
    dispatch(clearErrors());
    dispatch(clearCreateState());
    dispatch(getAreas());
  }, [dispatch]);

  useEffect(() => {
    setAreas(data);
  }, [data]);

  useEffect(() => {
    setErrorsMessage(errors);
  }, [errors]);

  const handleSelectArea = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    const area = areas.find(
      (item) => item.area_id == parseInt(event.target.value),
    );
    if (area && !selectedAreas.find((item) => item.area_id == area.area_id)) {
      const selectedValue = selectedAreas;
      selectedValue.push(area);
      setSelectedAreas([...selectedValue]);

      const messages = { ...errorsMessage };
      if (messages?.target_area) {
        delete messages.target_area;
        setErrorsMessage(messages);
      }
      setDataAdd({
        ...dataAdd,
        target_area: selectedValue.map((area) => area.area_id),
      });
    }
  };

  // eslint-disable-next-line no-undef
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    setDataAdd({
      ...dataAdd,
      [key]: e.target.value,
    });
    const messages = { ...errorsMessage };
    if (key == 'title' && messages?.title) delete messages.title;
    if (key == 'detail' && messages?.detail) delete messages.detail;
    setErrorsMessage(messages);
  };

  const handleDeleteArea = (areaId: number) => {
    const selectedValue = [...selectedAreas];
    const area = selectedValue.find((item) => item.area_id == areaId);
    if (area) {
      const index = selectedValue.indexOf(area);
      selectedValue.splice(index, 1);
      setSelectedAreas(selectedValue);
      setDataAdd({
        ...dataAdd,
        target_area: selectedValue.map((area) => area.area_id),
      });
    }
  };

  return (
    <Layout title={translate('notification.title')}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack flexDirection="row" sx={{ height: '82px' }}>
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('notification.label.title')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              className={styles.inputField}
              id="title"
              name="title"
              type="text"
              variant="standard"
              value={dataAdd.title}
              error={!!errorsMessage?.title ?? false}
              onChange={handleOnChange}
            />
            {errorsMessage?.title && errorsMessage.title.length > 0 && (
              <Box sx={{ marginTop: '14px' }}>
                <ErrorMessage message={errorsMessage.title[0]} />
              </Box>
            )}
          </Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center" sx={{ height: '180px' }}>
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('notification.label.detail')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              className={styles.inputField}
              id="detail"
              name="detail"
              type="text"
              label="Label"
              variant="outlined"
              multiline
              rows={4}
              value={dataAdd.detail}
              error={!!errorsMessage?.detail ?? false}
              onChange={handleOnChange}
            />
            {errorsMessage?.detail && errorsMessage.detail.length > 0 && (
              <Box sx={{ marginTop: '14px' }}>
                <ErrorMessage message={errorsMessage.detail[0]} />
              </Box>
            )}
          </Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center" sx={{ height: '120px' }}>
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('notification.label.target_area')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, height: '100%' }}>
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{ height: '100%' }}
              className={styles.inputField}
            >
              <Box
                sx={{ minWidth: 'calc(200 / 736 * 100%)', marginRight: '8px' }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: NOTICE,
                    fontWeight: '600',
                    marginLeft: '8px',
                  }}
                >
                  {translate('safety.create.label.target_area.specific_area')}
                </Typography>
                <FormControl fullWidth sx={{ marginTop: '8px' }}>
                  <InputLabel id="area-select-label">
                    {translate('safety.create.label.target_area.area')}
                  </InputLabel>
                  <Select
                    labelId="area-select-label"
                    id="area-select"
                    value={value}
                    label={translate('safety.create.label.target_area.area')}
                    className={styles.customSelectInput}
                    onChange={handleSelectArea}
                    error={!!errorsMessage?.target_area ?? false}
                  >
                    {areas.map((area: IArea) => (
                      <MenuItem key={area.area_id} value={area.area_id}>
                        {area.area_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorsMessage?.target_area &&
                  errorsMessage.target_area.length > 0 && (
                    <Box sx={{ marginTop: '14px' }}>
                      <ErrorMessage message={errorsMessage.target_area[0]} />
                    </Box>
                  )}
              </Box>
              <Stack
                alignContent="flex-start"
                flexWrap="wrap"
                flexDirection="row"
                gap={1}
                sx={{
                  flexGrow: 1,
                  backgroundColor: '#F2F2F2',
                  borderRadius: '5px',
                  padding: '8px 12px',
                  height: '100%',
                  overflowY: 'auto',
                }}
              >
                {selectedAreas.map((area) => (
                  <Chip
                    key={area.area_id}
                    label={area.area_name}
                    sx={{
                      backgroundColor: LIGHT_PURPLE,
                      width: '100px',
                    }}
                    textSx={{
                      color: TYPOGRAPHY,
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                    onClick={() => handleDeleteArea(area.area_id)}
                  />
                ))}
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="center"
          gap={5}
          sx={{ marginY: '40px' }}
        >
          <Button
            type="submit"
            label={translate('notification.button.create')}
            sx={{
              width: '40px',
              height: '38px',
              borderRadius: 0,
              boxShadow: 0,
            }}
            textSx={{ fontSize: '14px', fontWeight: 'bold' }}
          />
          <TableButton
            label={translate('notification.button.back')}
            onClick={handleBackButton}
          />
        </Stack>
      </FormProvider>
      <CreatePopup
        open={openCreateModal}
        onConfirm={() => navigate('/seminars')}
        onClose={() => navigate('/seminars')}
      />
    </Layout>
  );
};
export default CreateNotificationScreen;
