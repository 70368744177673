import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import { IUserType, translate } from '../../libs/utils';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  LIGHT_PURPLE,
  MAIN_LIGHT_COLOR,
  TYPOGRAPHY,
  WHITE,
} from '../../themes/colors';
import Chip from '../../components/Chip';
import Button from '../../components/Button';
import { useAppDispatch } from '../../redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getDetailSafety } from '../../redux/safety/action';
import { ISafety } from '../../redux/safety/types';
import { useSelector } from 'react-redux';
import { safetySelector } from '../../redux/safety/selectors';
import { UserType } from '../../configs';
import { TOPIC_LABEL } from '../../themes/fonts';

const useStyles = makeStyles({
  labelContainer: {
    width: 'calc(241 / 1218 * 100%)',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '18px',
    height: '100%',
  },

  textLabel: {
    color: WHITE,
    fontSize: `${TOPIC_LABEL} !important`,
    fontWeight: 'bold !important',
    textAlign: 'center',
  },

  inputField: {
    width: 'calc(718 / 959 * 100%)',
  },

  selectContainer: {
    width: '160px',
    marginLeft: '40px',
  },

  customSelectInput: {
    borderRadius: '12px !important',
    height: '50px !important',
  },
});

const memberTypes: IUserType[] = [
  {
    value: UserType.REGULAR,
    type: translate('member.list.table.membership_type.regular'),
  },
  {
    value: UserType.ASSOCIATE,
    type: translate('member.list.table.membership_type.associate'),
  },
  {
    value: UserType.ADMIN_STAFF,
    type: translate('member.list.table.membership_type.admin_staff'),
  },
  {
    value: UserType.ALL_MEMBER,
    type: translate('member.list.table.membership_type.all_member'),
  },
];

const DetailSafetyScreen: React.FC = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const safety: ISafety = useSelector(safetySelector);

  useEffect(() => {
    if (id) dispatch(getDetailSafety({ id: parseInt(id) }));
  }, [dispatch, id]);

  const handleRedirectBackButton = () => navigate('/safety');

  return (
    <Layout title={translate('safety.detail.title')} maxWidth={1218}>
      <Stack flexDirection="row" alignItems="center" sx={{ height: '82px' }}>
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('participant.detail.label.title')}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            className={styles.inputField}
            type="text"
            variant="standard"
            value={safety.title}
          />
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" sx={{ height: '100px' }}>
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('safety.detail.label.detail')}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, paddingY: '10px' }}>
          <TextField
            className={styles.inputField}
            type="text"
            label="Label"
            variant="outlined"
            multiline
            maxRows={2}
            value={safety.detail}
          />
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" sx={{ height: '82px' }}>
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('safety.detail.label.membership_type')}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: '24px' }}>
          <FormControl className={styles.selectContainer}>
            <InputLabel id="membership-type-select-label">
              {translate('member.create.label.membership_type.select')}
            </InputLabel>
            <Select
              labelId="membership-type-select-label"
              id="membership-type-select"
              label={translate('member.create.label.membership_type.select')}
              className={styles.customSelectInput}
              value={safety.user_type}
            >
              {memberTypes.map((type: IUserType) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Stack flexDirection="row" alignItems="center" sx={{ height: '104px' }}>
        <Box className={styles.labelContainer}>
          <Typography className={styles.textLabel}>
            {translate('safety.detail.label.target_area')}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <Box
            className={styles.inputField}
            sx={{
              backgroundColor: '#F2F2F2',
              borderRadius: '5px',
              height: '100%',
            }}
          >
            <Box
              display="flex"
              alignContent="flex-start"
              flexWrap="wrap"
              flexDirection="row"
              gap={1}
              sx={{
                padding: '8px 12px',
              }}
            >
              {safety.target_area.map((area, index) => (
                <Chip
                  key={index}
                  label={area.area_name}
                  sx={{
                    backgroundColor: LIGHT_PURPLE,
                    width: '80px',
                  }}
                  textSx={{
                    color: TYPOGRAPHY,
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                  closeIcon={false}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="center"
        gap={5}
        sx={{ marginTop: '25px' }}
      >
        <Button
          label={translate('button.back')}
          onClick={handleRedirectBackButton}
          sx={{ width: '100px', height: '38px', borderRadius: 0, boxShadow: 0 }}
          textSx={{ fontSize: '14px', fontWeight: 'bold' }}
        />
      </Stack>
    </Layout>
  );
};
export default DetailSafetyScreen;
